<template>
  <div>
    <f7-row class="prueba">
      <f7-col style="width:60%">
        <div class="content-devx">
          <DxForm
            id="orderForm"
          >
            <DxGroupItem
              css-class="content-combo"
              :col-count="2"
            >
              <DxSimpleItem
                name="client"
                :data-field="$t('newOrder.newOrder_client')"
                :editor-options="{
                  value: newOrder.customer,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeClient,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                name="order"
                :data-field="$t('newOrder.newOrder_order')"
                :editor-options="{
                  value: newOrder.order,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeOrder,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                name="issued"
                :data-field="$t('newOrder.newOrder_emit')"
                :editor-options="{
                  value: newOrder.date_emmission,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeIssued,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                name="delivery"
                :data-field="$t('newOrder.newOrder_delivery')"
                :editor-options="{
                  value: newOrder.date_deliver,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeDelivery,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_price_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                name="email"
                :data-field="$t('newOrder.newOrder_address')"
                :col-span="2"
                :editor-options="{
                  value: newOrder.destiny,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeDirection,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                name="status"
                :data-field="$t('status')"
                editor-type="dxSelectBox"
                :editor-options="{
                  items: newOrder.status === 'sent' ? ['sent'] : listStatus,
                  value: newOrder.status,
                  displayExpr: displayTranslatedStatus,
                  readOnly: newOrder.status === 'sent',
                  onValueChanged: onChangeStatus,
                }"
              >
                <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
              </DxSimpleItem>
              <DxSimpleItem
                :data-field="$t('OrderTable.totalValue')"
                editor-type="dxNumberBox"
                :editor-options="{
                  value: parseFloat(sumatory).toFixed(2),
                  readOnly: true,
                }"
              />
            </Dxgroupitem>
          </DxForm>
          <div class="content-datagrid">
            <DxDataGrid
              :data-source="newOrder.articles"
              :allow-column-reordering="true"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="false"
              :column-auto-width="true"
              :row-alternation-enabled="true"
              :word-wrap-enabled="true"
            >
              <DxSelection
                mode="single"
              />
              <DxStateStoring
                :enabled="true"
                type="localStorage"
                storage-key="DxViewOrder"
              />
              <DxEditing
                :allow-updating="true"
                start-edit-action="click"
                mode="cell"
              />
              <DxExport
                :enabled="false"
              />
              <DxPaging :page-size="10" />
              <DxPager
                :show-page-size-selector="true"
                :show-info="true"
              />
              <DxHeaderFilter :visible="true" />
              <DxSearchPanel
                :visible="true"
                :width="200"
                :placeholder="$t('Search_placeholder')"
              />
              <DxColumnFixing :enabled="true" />
              <DxColumn
                data-field="article"
                :caption="$t('OrderTable.article')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="description"
                :caption="$t('OrderTable.description')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="volume"
                :caption="$t('OrderTable.quantity')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="unit"
                :caption="$t('OrderTable.unit')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="price_unit"
                :caption="$t('OrderTable.price_unit')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="boxes"
                :caption="$t('OrderTable.boxes')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="palets"
                :caption="$t('OrderTable.palets')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="lot"
                :caption="$t('OrderTable.lot')"
                :allow-header-filtering="false"
                alignment="left"
              />
              <DxColumn
                data-field="bill_amount"
                :caption="$t('OrderTable.totalValue')"
                :allow-header-filtering="false"
                alignment="left"
              />
            </DxDataGrid>
          </div>
        </div>
      </f7-col>
      <f7-col>
        <div class="content-devx">
          <embed
            :src="newOrder.file"
            width="100%"
            frameborder="0"
          >
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
} from 'devextreme-vue/data-grid';

export default {
  name: 'NewOrder',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxEditing,
  },
  props: {
    objectWithOrder: { type: Object, default: () => {} },
  },
  data() {
    return {
      sumatory: 0,
    };
  },
  computed: {
    ...mapState('Order', ['isNewUser', 'newOrder', 'listStatus', 'listStatusWithout']),
  },
  beforeMount() {
    if (this.newOrder.articles.length > 0) {
      this.calculateTotalPrice();
    }
  },
  methods: {
    back() {
      this.setIsNewUser(false);
    },
    register() {
      this.resetNewUser();
    },
    onChangeClient(e) {
      this.setClient(e.value);
    },
    onChangeIssued(e) {
      this.setIssued(e.value);
    },
    onChangeDelivery(e) {
      this.setDelivery(e.value);
    },
    onChangeDirection(e) {
      this.setDirection(e.value);
    },
    onChangeOrder(e) {
      this.setOrder(e.value);
    },
    onChangeStatus(e) {
      this.setListStatus(e.value);
    },
    displayTranslatedStatus(status) {
      if (status === null) {
        return '';
      }
      return `${this.$t(`OrderTable.status.${status}`)}`;
    },
    calculateTotalPrice() {
      for (const article of this.newOrder.articles) {
        // this.sumatory += article.unit === '' ? article.price : (article.price * article.amount);
        this.sumatory += article.bill_amount;
      }
      if (!this.sumatory) {
        this.sumatory = 0;
      }
    },
    ...mapActions('Order', ['setClient', 'setIssued', 'setDelivery', 'setDirection',
      'setOrder', 'resetNewOrder', 'setListStatus']),
  },
};
</script>

<style lang="scss" scope>
  .content-datagrid {
    padding-top: 30px;
  }
  .prueba {
    display: flex;
  }
  .prueba .col{
    align-self: stretch;
    width: 40%;
  }
  .prueba .col embed{
    position: relative;
    height: 75vh;
  }
</style>
